<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <nerve-dialog v-model="dialogState" scrollable width="600">
    <v-card class="update-wl">
      <v-card-title>
        {{ $t('updateDeployedWorkload.availableVersions') }}
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0 text-left">
          <v-simple-table
            id="iiotUpdateDeployedWorkloadDialogSimpleTable"
            v-model="selectedVersion"
            fixed-header
            :height="getVersions.length >= 4 ? '30vh' : getVersions.length === 3 ? '20vh' : ''"
          >
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('updateDeployedWorkload.name') }}
                  </th>
                  <th class="text-left">
                    {{ $t('updateDeployedWorkload.releaseName') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in getVersions"
                  :key="item.name"
                  :class="{ primary: item.id === selectedVersion.id }"
                  class="pointer"
                  @click="selectVersion(item)"
                >
                  <td
                    :class="{
                      'version-class': item.name === version,
                      primary: item.id === selectedVersion.id,
                    }"
                  >
                    {{ item.name }}
                  </td>
                  <td
                    :class="{
                      'version-class': item.name === version,
                      primary: item.id === selectedVersion.id,
                    }"
                  >
                    {{ item.releaseName }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <nerve-button
          id="iiotUpdateDeployedWorkloadDialogCancel"
          :text="$t('updateDeployedWorkload.cancel')"
          type-of-btn="cancel"
          size="normal"
          @click-event="close()"
        />
        <nerve-button
          id="iiotUpdateDeployedWorkloadDialogUpdate"
          :text="$t('updateDeployedWorkload.update')"
          :disabled="!selectedVersion.id"
          type-of-btn="action"
          size="normal"
          class="ml-3"
          @click-event="update()"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';
import Logger from '@/utils/logger';

export default {
  components: { NerveButton, NerveDialog },
  props: {
    workloadId: {
      type: String,
      default: '',
    },
    version: {
      type: String,
      default: '',
    },
    serialNumber: {
      type: String,
      default: '',
    },
    disabledUpdateButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedVersion: {},
    deployObject: {
      retryTimes: 3,
      dryRun: false,
    },
  }),
  computed: {
    dialogState: {
      get() {
        return this.$store.getters['workload-deploy-update/getUpdateWorkloadDialogState'];
      },
      set() {
        this.close();
      },
    },
    getVersions() {
      return this.$store.getters['workload-deploy-update/getVersions'];
    },
  },
  methods: {
    async update() {
      try {
        this.prepareDeployObj();
        await this.$store.dispatch('workload-deploy-update/update_deployed_workload', this.deployObject);
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'updateDeployedWorkload.successUpdate',
          color: 'primary',
          showClose: true,
        });
        setTimeout(() => {
          this.$router.push({ name: 'Deploy Log List' });
        }, 1200);
        this.close();
      } catch (e) {
        Logger.error(e);
      }
    },
    isDisabledDialogButtonUpdate() {
      return Object.keys(this.selectedVersion).length === 0;
    },
    prepareDeployObj() {
      this.deployObject.deployName = new Date().toLocaleString('en-GB');
      this.deployObject.nodes = [this.serialNumber];
      this.deployObject.workloadId = this.workloadId;
      this.deployObject.versionId = this.selectedVersion.id;
    },
    selectVersion(item) {
      this.selectedVersion = item;
    },
    close() {
      this.$store.dispatch('workload-deploy-update/close_update_workload_dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  border: 2px solid #00c5ea;
}

.selected {
  color: primary;
}

.title {
  > h1 {
    text-align: left;
    font-size: 25px !important;
    font-weight: 800 !important;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.update-wl {
  min-height: 320px !important;
}

.dialog-height {
  height: 80%;
}
.version-class {
  background-color: var(--v-treeNodeExpanded-base);
}
.pointer {
  cursor: pointer !important;
}
</style>
